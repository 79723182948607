import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components';
import { IconBell, IconChecks, IconInnerShadowTopRight } from '@tabler/icons-react';
import { Bell } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface NotificationItemProps {
  isNew: boolean;
}

export function NotificationItem({ isNew = false }: NotificationItemProps) {
  return (
    <DropdownMenuItem>
      <div
        className={`w-full flex items-center space-x-4 rounded-md p-2 hover:bg-accent hover:text-accent-foreground ${
          isNew ? 'bg-accent' : 'opacity-70'
        }`}
      >
        <Bell className="h-5 w-5" />
        <div className="space-y-1 w-full">
          <p className="text-sm font-medium leading-none flex  justify-between">
            Everything {isNew && <IconInnerShadowTopRight color="blue" size={15} />}
          </p>
          <p className="text-sm text-muted-foreground">Email digest, mentions & all activity.</p>
          <p className={`text-xs ${isNew ? 'text-blue-700 font-semibold' : 'text-muted-foreground'} `}>2 days ago</p>
        </div>
      </div>
    </DropdownMenuItem>
  );
}

export function NotificationNav() {
  const { t: translate } = useTranslation('translation');

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon" className="rounded-full">
          <IconBell size={18} className="text-black dark:text-white" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-96" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex  space-y-1 justify-between items-start">
            <div className="grid gap-2">
              <p className="text-sm font-bold leading-none">
                {translate('systems.elements.navigation.elements.notificationsToggle.content.title')}
              </p>
              <p className="text-xs leading-none text-muted-foreground">
                {translate('systems.elements.navigation.elements.notificationsToggle.content.description')}
              </p>
            </div>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <NotificationItem isNew={true} />
          <NotificationItem isNew={true} />
          <NotificationItem isNew={true} />
          <NotificationItem isNew={false} />
          <NotificationItem isNew={false} />
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuLabel className="font-normal">
          <div className="flex justify-between items-center">
            <Button variant={'ghost'} className="text-sm font-bold leading-none">
              {translate(
                'systems.elements.navigation.elements.notificationsToggle.elements.allNotificationButtonTitle',
              )}
            </Button>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <IconChecks className="hover:text-blue-400" size={20} />
                </TooltipTrigger>
                <TooltipContent>
                  <p>
                    {' '}
                    {translate(
                      'systems.elements.navigation.elements.notificationsToggle.tooltips.markAllReadTooltipsTitle',
                    )}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </DropdownMenuLabel>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
