import { config } from '@/config';
import { PERMISSION_ENUM } from '@/enums/roleKey';
import { translate } from '@/locales';
import { PATH_DASHBOARD } from '@/routes/paths.routes';
import {
  IconBrandTabler,
  IconChartHistogram,
  IconChartPie,
  IconInfoSquareRounded,
  IconMessageShare,
  IconUserSearch,
  IconWallet,
} from '@tabler/icons-react';

type Submenu = {
  href: string;
  label: string;
  active: boolean;
  permissionKey?: string;
};

type Menu = {
  href: string;
  label: string;
  active: boolean;
  icon: JSX.Element;
  submenus: Submenu[];
  permissionKey?: string;
  openNewTab?: boolean;
};

type Group = {
  groupLabel: string;
  menus: Menu[];
};

export function getMenuList(pathname: string): Group[] {
  return [
    {
      groupLabel: '',
      menus: [
        {
          href: PATH_DASHBOARD.company.profile.detail,
          label: translate('systems.elements.sidebar.content.companyProfile'),
          active: pathname.includes(PATH_DASHBOARD.company.profile.detail),
          icon: <IconInfoSquareRounded size={22} />,
          permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
          submenus: [
            // {
            //   href: PATH_DASHBOARD.company.profile.list,
            //   label: translate('systems.elements.sidebar.content.companyProfile'),
            //   active: pathname.includes(PATH_DASHBOARD.company.profile.list),
            //   permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
            // },
            // {
            //   href: PATH_DASHBOARD.company.members.list,
            //   label: translate('systems.elements.sidebar.content.members'),
            //   active: pathname.includes(PATH_DASHBOARD.company.members.list),
            //   permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
            // },
            // {
            //   href: PATH_DASHBOARD.company.rolePermissions.list,
            //   label: translate('systems.elements.sidebar.content.rolePermission'),
            //   active: pathname.includes(PATH_DASHBOARD.company.rolePermissions.list),
            //   permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
            // },
          ],
        },
      ],
    },
    {
      groupLabel: '',
      menus: [
        {
          href: PATH_DASHBOARD.jobAdvertisement.postJobAdvertisement,
          label: translate('systems.elements.sidebar.content.jobAdvertisement'),
          active: pathname.includes(PATH_DASHBOARD.jobAdvertisement.root),
          icon: <IconMessageShare size={20} />,
          permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
          submenus: [
            {
              href: PATH_DASHBOARD.jobAdvertisement.postJobAdvertisement,
              label: translate('systems.elements.sidebar.content.postJobAdvertisement'),
              active: pathname.includes(PATH_DASHBOARD.jobAdvertisement.postJobAdvertisement),
              permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
            },
            {
              href: PATH_DASHBOARD.jobAdvertisement.manageJobAdvertisement.list,
              label: translate('systems.elements.sidebar.content.manageJobAdvertisement'),
              active: pathname.includes(PATH_DASHBOARD.jobAdvertisement.manageJobAdvertisement.list),
              permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
            },
          ],
        },
      ],
    },
    {
      groupLabel: '',
      menus: [
        {
          href: PATH_DASHBOARD.candidate.findCandidate,
          label: translate('systems.elements.sidebar.content.candidateProfile'),
          active: pathname.includes(PATH_DASHBOARD.candidate.root),
          icon: <IconUserSearch size={20} />,
          permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
          submenus: [
            // {
            //   href: PATH_DASHBOARD.candidate.findCandidate,
            //   label: translate('systems.elements.sidebar.content.findCandidates'),
            //   active: pathname.includes(PATH_DASHBOARD.candidate.findCandidate),
            //   permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
            // },
            {
              href: PATH_DASHBOARD.candidate.manageCandidate,
              label: translate('systems.elements.sidebar.content.manageCandidates'),
              active: pathname.includes(PATH_DASHBOARD.candidate.manageCandidate),
              permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
            },
            {
              href: PATH_DASHBOARD.candidate.interviewAssessment,
              label: translate('systems.elements.sidebar.content.interviewAndAssessment'),
              active: pathname.includes(PATH_DASHBOARD.candidate.interviewAssessment),
              permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
            },
          ],
        },
      ],
    },

    // {
    //   groupLabel: '',
    //   menus: [
    //     {
    //       href: PATH_DASHBOARD.candidate.findCandidate,
    //       label: translate('systems.elements.sidebar.content.media'),
    //       active: pathname.includes('/users'),
    //       icon: <IconAd2 size={20} />,
    //       permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
    //       submenus: [
    //         {
    //           href: PATH_DASHBOARD.candidate.findCandidate,
    //           label: translate('systems.elements.sidebar.content.postContent'),
    //           active: pathname.includes('/users'),
    //           permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
    //         },
    //         {
    //           href: PATH_DASHBOARD.candidate.findCandidate,
    //           label: translate('systems.elements.sidebar.content.evaluationManagement'),
    //           active: pathname.includes('/users'),
    //           permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
    //         },
    //       ],
    //     },
    //   ],
    // },
    {
      groupLabel: '',
      menus: [
        {
          href: PATH_DASHBOARD.finance.myWallet,
          label: translate('systems.elements.sidebar.content.financeAndTransactionHistory'),
          active: pathname.includes(PATH_DASHBOARD.finance.myWallet),
          icon: <IconWallet size={20} />,
          permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
          submenus: [],
        },
      ],
    },
    // {
    //   groupLabel: '',
    //   menus: [
    //     {
    //       href: PATH_DASHBOARD.candidate.findCandidate,
    //       label: translate('systems.elements.sidebar.content.helpAndInformation'),
    //       active: pathname.includes('/users'),
    //       icon: <IconInfoSquareRounded size={22} />,
    //       permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
    //       submenus: [
    //         {
    //           href: PATH_DASHBOARD.candidate.findCandidate,
    //           label: translate('systems.elements.sidebar.content.legalInformation'),
    //           active: pathname.includes('/users'),
    //           permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
    //         },
    //         {
    //           href: PATH_DASHBOARD.candidate.findCandidate,
    //           label: translate('systems.elements.sidebar.content.marketAndDevelopment'),
    //           active: pathname.includes('/users'),
    //           permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
    //         },
    //       ],
    //     },
    //   ],
    // },
    {
      groupLabel: '',
      menus: [
        {
          href: PATH_DASHBOARD.general.app,
          label: translate('systems.elements.sidebar.content.statisticalReport'),
          active: pathname.includes(PATH_DASHBOARD.general.app),
          icon: <IconChartPie size={20} />,
          submenus: [],
          permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
        },
      ],
    },
    {
      groupLabel: '',
      menus: [
        {
          href: `${config.LANDING_PAGE_URL}/job/list`,
          openNewTab: true,
          label: translate('systems.elements.sidebar.content.marketAndDevelopment'),
          active: pathname.includes(PATH_DASHBOARD.general.app),
          icon: <IconChartHistogram size={20} />,
          submenus: [],
          permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
        },
      ],
    },
    {
      groupLabel: '',
      menus: [
        {
          href: PATH_DASHBOARD.personalization.profile,
          label: translate('systems.elements.sidebar.content.personalization'),
          active: pathname.includes(PATH_DASHBOARD.personalization.root),
          icon: <IconBrandTabler size={22} />,
          permissionKey: PERMISSION_ENUM.BANK_ACCOUNT_INFO_CREATE,
          submenus: [],
        },
      ],
    },
  ];
}
