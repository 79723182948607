import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { AuthGuard, GuestGuard, RoleGuard } from '@/guards';
import { AuthenticationPages, DashboardPages, PublicPages } from '.';
import { DashboardLayout, PublicLayout } from '@/layouts';
import { PATH_AFTER_LOGIN, PATH_AUTH, PATH_DASHBOARD, PATH_PUBLIC } from './paths.routes';
import { PERMISSION_KEYS } from '@/enums/roleKey';

export const Router: React.FC = () => {
  const routes: RouteObject[] = [
    // Auth Routes
    {
      path: '/auth',
      element: (
        <GuestGuard>
          <PublicLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: PATH_AUTH.login,
          element: <AuthenticationPages.LoginPage />,
        },
      ],
    },

    // Intro Routes


    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={PATH_AFTER_LOGIN} replace />,
          index: true,
        },
        {
          path: PATH_DASHBOARD.general.app,
          element: (
            <RoleGuard rolePermission={PERMISSION_KEYS.BANK_ACCOUNT_INFO.VIEW}>
              <DashboardPages.DashboardPage />
            </RoleGuard>
          ),
        },

        //Candidate
        {
          children: [
            {
              path: PATH_DASHBOARD.candidate.findCandidate,
              element: (
                <RoleGuard rolePermission={PERMISSION_KEYS.CANDIDATE_EXPERIENCE.VIEW}>
                  <DashboardPages.FindCandidate />
                </RoleGuard>
              ),
            },
            {
              path: PATH_DASHBOARD.candidate.interviewAssessment,
              element: (
                <RoleGuard rolePermission={PERMISSION_KEYS.CANDIDATE_EXPERIENCE.VIEW}>
                  <DashboardPages.InterviewAssessment />
                </RoleGuard>
              ),
            },
            {
              path: PATH_DASHBOARD.candidate.manageCandidate,
              element: (
                <RoleGuard rolePermission={PERMISSION_KEYS.CANDIDATE_EXPERIENCE.VIEW}>
                  <DashboardPages.ManageCandidate />
                </RoleGuard>
              ),
            },
          ],
        },

        //Job Advertisement
        {
          children: [
            {
              path: PATH_DASHBOARD.jobAdvertisement.postJobAdvertisement,
              element: (
                <RoleGuard rolePermission={PERMISSION_KEYS.JOB.CREATE}>
                  <DashboardPages.PostJobAdvertisementPage />
                </RoleGuard>
              ),
            },
            {
              path: PATH_DASHBOARD.jobAdvertisement.manageJobAdvertisement.list,
              element: (
                <RoleGuard rolePermission={PERMISSION_KEYS.JOB.VIEW}>
                  <DashboardPages.ManageJobAdvertisementPage />
                </RoleGuard>
              ),
            },
          ],
        },

        //Finance
        {
          children: [
            {
              path: PATH_DASHBOARD.finance.myWallet,
              element: (
                <RoleGuard rolePermission={PERMISSION_KEYS.JOB.CREATE}>
                  <DashboardPages.FinanceAndTransactionsPage />
                </RoleGuard>
              ),
            },
          ],
        },

        //Company
        {
          children: [
            {
              path: PATH_DASHBOARD.company.profile.detail,
              element: (
                <RoleGuard rolePermission={PERMISSION_KEYS.JOB.VIEW}>
                  <DashboardPages.CompanyProfilePage />
                </RoleGuard>
              ),
            },
            {
              path: PATH_DASHBOARD.company.members.list,
              element: (
                <RoleGuard rolePermission={PERMISSION_KEYS.JOB.VIEW}>
                  <DashboardPages.MembersPage />
                </RoleGuard>
              ),
            },

            {
              path: PATH_DASHBOARD.company.rolePermissions.list,
              element: (
                <RoleGuard rolePermission={PERMISSION_KEYS.JOB.VIEW}>
                  <DashboardPages.MembersPage />
                </RoleGuard>
              ),
            },
          ],
        },

        //Personalization
        {
          path: PATH_DASHBOARD.personalization.root,
          element: (
            <RoleGuard rolePermission={PERMISSION_KEYS.JOB.VIEW}>
              <DashboardPages.PersonalizationPage />
            </RoleGuard>
          ),
          children: [
            {
              path: PATH_DASHBOARD.personalization.root,
              element: (
                <RoleGuard rolePermission={PERMISSION_KEYS.JOB.VIEW}>
                  <DashboardPages.PersonalizationPage />
                </RoleGuard>
              ),
            },
            {
              path: PATH_DASHBOARD.personalization.appearance,
              element: (
                <RoleGuard rolePermission={PERMISSION_KEYS.JOB.VIEW}>
                  <DashboardPages.AppearanceSettingsPage />
                </RoleGuard>
              ),
            },
            {
              path: PATH_DASHBOARD.personalization.profile,
              element: (
                <RoleGuard rolePermission={PERMISSION_KEYS.JOB.VIEW}>
                  <DashboardPages.ProfileSettingsPage />
                </RoleGuard>
              ),
            },
            {
              path: PATH_DASHBOARD.personalization.security,
              element: (
                <RoleGuard rolePermission={PERMISSION_KEYS.JOB.VIEW}>
                  <DashboardPages.SecuritySettingsPage />
                </RoleGuard>
              ),
            },
          ],
        },
      ],
    },

    // Public Routes
    {
      path: '/',
      element: <PublicLayout />,
      children: [
        { path: PATH_PUBLIC.page403, element: <PublicPages.Forbidden /> },
        { path: PATH_PUBLIC.page404, element: <PublicPages.NotFound /> },
        {
          path: PATH_PUBLIC.page500,
          element: <PublicPages.InternalServerError />,
        },
        {
          path: PATH_PUBLIC.maintenance,
          element: <PublicPages.MaintenanceError />,
        },
        { path: PATH_PUBLIC.comingSoon, element: <PublicPages.ComingSoon /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to={PATH_PUBLIC.page404} replace />,
    },
  ];

  return useRoutes(routes);
};
