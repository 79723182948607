const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';
// ROOT PATH AFTER LOGIN SUCCESSFUL

export const PATH_PUBLIC = {
  comingSoon: `${ROOTS_DASHBOARD}/coming-soon`,
  maintenance: `${ROOTS_DASHBOARD}/maintenance`,
  page403: `${ROOTS_DASHBOARD}/403`,
  page404: `${ROOTS_DASHBOARD}/404`,
  page500: `${ROOTS_DASHBOARD}/500`,
  intro: `${ROOTS_DASHBOARD}/intro`,
  getStarted: {
    root: `${ROOTS_DASHBOARD}/get-started`,
    candidate: `${ROOTS_DASHBOARD}/get-started/candidate`,
    employer: `${ROOTS_DASHBOARD}/get-started/employer`,
  },
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: `${ROOTS_AUTH}/login`,
  register: `${ROOTS_AUTH}/register`,
  verify: `${ROOTS_AUTH}/verify`,
  resetPassword: `${ROOTS_AUTH}/reset-password`,
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: `${ROOTS_DASHBOARD}/overview`,
  },

  //Candidate
  candidate: {
    root: `${ROOTS_DASHBOARD}/candidate`,
    findCandidate: `${ROOTS_DASHBOARD}/candidate/find`,
    interviewAssessment: `${ROOTS_DASHBOARD}/candidate/interview-assessment`,
    manageCandidate: `${ROOTS_DASHBOARD}/candidate/manage`,
  },

  //JobAdvertisement
  jobAdvertisement: {
    root: `${ROOTS_DASHBOARD}/job-advertisement`,
    postJobAdvertisement: `${ROOTS_DASHBOARD}/job-advertisement/create`,
    manageJobAdvertisement: {
      list: `${ROOTS_DASHBOARD}/job-advertisement/manage-jobAdvertisement`,
      create: `${ROOTS_DASHBOARD}/job-advertisement/manage-jobAdvertisement/create`,
      update: (id: string | number) => `${ROOTS_DASHBOARD}/job-advertisement/manage-jobAdvertisement/update/${id}`,
      detail: (id: string | number) => `${ROOTS_DASHBOARD}/job-advertisement/manage-jobAdvertisement/detail/${id}`,
    },
    moderateJobAdvertisement: {
      list: `${ROOTS_DASHBOARD}/job-advertisement/moderate-jobAdvertisement`,
      create: `${ROOTS_DASHBOARD}/job-advertisement/moderate-jobAdvertisement/create`,
      update: (id: string | number) => `${ROOTS_DASHBOARD}/job-advertisement/moderate-jobAdvertisement/update/${id}`,
      detail: (id: string | number) => `${ROOTS_DASHBOARD}/job-advertisement/moderate-jobAdvertisement/detail/${id}`,
    },
  },

  //Finance
  finance: {
    root: `${ROOTS_DASHBOARD}/finance`,
    myWallet: `${ROOTS_DASHBOARD}/finance/myWallet`,
  },

  //Company
  company: {
    root: `${ROOTS_DASHBOARD}/company`,
    profile: {
      detail: `${ROOTS_DASHBOARD}/company/profile`,
    },
    members: {
      list: `${ROOTS_DASHBOARD}/company/members`,
      create: `${ROOTS_DASHBOARD}/company/members/create`,
      update: (id: string | number) => `${ROOTS_DASHBOARD}/company/members/update/${id}`,
      detail: (id: string | number) => `${ROOTS_DASHBOARD}/company/members/detail/${id}`,
    },
    rolePermissions: {
      list: `${ROOTS_DASHBOARD}/company/role-permissions`,
      create: `${ROOTS_DASHBOARD}/company/role-permissions/create`,
      update: (id: string | number) => `${ROOTS_DASHBOARD}/company/role-permissions/update/${id}`,
      detail: (id: string | number) => `${ROOTS_DASHBOARD}/company/role-permissions/detail/${id}`,
    },
  },

  //Personalization
  personalization: {
    root: `${ROOTS_DASHBOARD}/personalization`,
    appearance: `${ROOTS_DASHBOARD}/personalization/appearance`,
    security: `${ROOTS_DASHBOARD}/personalization/security`,
    profile: `${ROOTS_DASHBOARD}/personalization/profile`,
  },
};

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.company.profile.detail; // as '/app'
