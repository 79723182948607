import { config } from '@/config';

function path(root: string, subLink: string) {
  return `${root}${subLink}`;
}

// BASE URL
const BASE_URL = path(config.BASE_URL, '/hrdept-ws');

// API URL
const AUTH_URL = path(BASE_URL, '/api/v1/auth');

// API URL
const API_URL = path(BASE_URL, '/api/v1');

// API URL VERSION2
const API_URL_V2 = path(BASE_URL, '/api/v2');


// ALEPAY URL
export const ALEPAY_URL = path(API_URL, '/alepay');
export const ALEPAY_CANCEL_URL = path(ALEPAY_URL, '/cancel');
export const ALEPAY_RETURN_URL = path(ALEPAY_URL, '/return');

// AUTHENTICATION URL
export const ADMIN_SIGN_IN_URL = path(AUTH_URL, '/admin-signin');
export const CANDIDATE_SOCIAL_SIGN_IN_URL = path(AUTH_URL, '/candidate/social-login');
export const FORGOT_PASSWORD_URL = path(AUTH_URL, '/forgot-password');
export const NEW_PASSWORD_URL = path(AUTH_URL, '/new-password');
export const REFRESH_TOKEN_URL = path(AUTH_URL, '/refresh-token');
export const REGISTER_URL = path(AUTH_URL, '/register');
export const SIGN_IN_URL = path(AUTH_URL, '/signin');
export const SIGN_OUT_URL = path(AUTH_URL, '/signout');
export const VERIFY_OTP_URL = path(AUTH_URL, '/verify-otp');
export const VERIFY_COMPANY_URL = path(AUTH_URL, '/verify-company');
export const RESET_PASSWORD_URL = path(AUTH_URL, '/new-password');

// BANK ACCOUNT INFORMATION
export const BANK_ACCOUNT_URL = path(API_URL, '/bank-account');
export const BANK_ACCOUNT_COMPANY_URL = path(API_URL, '/bank-account/company');
export const SEARCH_BANK_ACCOUNT_WITH_CRITERIA_URL = path(BANK_ACCOUNT_URL, '/company-search');
export const FIND_ALL_BANK_FROM_THIRD_PARTY_VIETQR = 'https://api.vietqr.io/v2/banks';

// CANDIDATE URL
export const CANDIDATE_CV_URL = path(API_URL, '/candidate-cv');
export const ADMIN_SEARCH_CANDIDATE_CV_URL = path(CANDIDATE_CV_URL, '/admin');
export const ADMIN_SEARCH_CANDIDATE_CV_WITH_CRITERIA_URL = path(CANDIDATE_CV_URL, '/admin/search');
export const CONFIRM_CANDIDATE_CV_URL = path(CANDIDATE_CV_URL, '/confirm');
export const SEARCH_CANDIDATE_CV_WITH_SEARCH_TEXT_URL = path(CANDIDATE_CV_URL, '/find-by-search-text');
export const SEARCH_CANDIDATE_CV_WITH_CRITERIA_URL = path(CANDIDATE_CV_URL, '/search');
export const TOTAL_WAITING_CANDIDATE_CV_URL = path(CANDIDATE_CV_URL, '/total-waiting-confirm');

export const CANDIDATE_EDUCATION_URL = path(API_URL, '/candidate-educations');
export const SEARCH_CANDIDATE_EDUCATION_WITH_CRITERIA_URL = path(CANDIDATE_EDUCATION_URL, '/search');
export const SEARCH_CANDIDATE_EDUCATION_WITH_USER_ID_URL = path(CANDIDATE_EDUCATION_URL, '/user-id');

export const CANDIDATE_EVALUATION_COMPANIES_URL = path(API_URL, '/candidate-evaluation-companies');
export const SEARCH_CANDIDATE_EVALUATION_COMPANIES_WITH_CRITERIA_URL = path(
  CANDIDATE_EVALUATION_COMPANIES_URL,
  '/search',
);

export const CANDIDATE_EXPERIENCE_URL = path(API_URL, '/candidate-experiences');
export const SEARCH_CANDIDATE_EXPERIENCE_BY_USER_ID_URL = path(CANDIDATE_EXPERIENCE_URL, '/user-id');

export const CANDIDATE_INTERVIEW_RESULTS_URL = path(API_URL, '/candidate-interview-result');
export const CANDIDATE_INTERVIEW_PROCESS_URL = path(CANDIDATE_INTERVIEW_RESULTS_URL, '/process-id');

export const CANDIDATE_PROFILES_URL = path(API_URL, '/candidate-profiles');
export const SEARCH_CANDIDATE_PROFILES_WITH_CRITERIA_URL = path(CANDIDATE_PROFILES_URL, '/search');

// CAREER
export const CAREER_CATEGORY_URL = path(API_URL, '/career-categorys'); // API ĐANG SAI NGỮ PHÁP
export const SEARCH_CAREER_CATEGORY_WITH_CRITERIA_URL = path(CAREER_CATEGORY_URL, '/search');
export const SEARCH_ALL_CAREER_CATEGORY_URL = path(CAREER_CATEGORY_URL, '/all');

export const CAREER_URL = path(API_URL, '/careers');
export const SEARCH_CAREER_WITH_CRITERIA_URL = path(CAREER_URL, '/search');

// COMPANY URL
export const COMPANY_ARTICLE_URL = path(API_URL, '/company-articles');
export const SEARCH_COMPANY_ARTICLE_WITH_CRITERIA_URL = path(COMPANY_ARTICLE_URL, '/search');

export const COMPANY_URL = path(API_URL, '/companies');
export const CONFIRM_COMPANY_URL = path(COMPANY_URL, '/confirm');
export const SEARCH_COMPANY_WITH_CRITERIA_URL = path(COMPANY_URL, '/search');

export const COMPANY_EMPLOYEE_URL = path(API_URL, '/company-employees');
export const SEARCH_COMPANY_EMPLOYEE_WITH_CRITERIA_URL = path(COMPANY_URL, '/search');

export const COMPANY_EVALUATION_CANDIDATES_URL = path(API_URL, '/company-evaluation-candidates');
export const SEARCH_COMPANY_EVALUATION_CANDIDATES_WITH_CRITERIA_URL = path(
  COMPANY_EVALUATION_CANDIDATES_URL,
  '/search',
);
export const COMPANY_PROFILES_URL = path(API_URL, '/company-profiles');

export const COMPANY_SUBSCRIBERS_URL = path(API_URL, '/company-subscribers');
export const SEARCH_COMPANY_SUBSCRIBERS_WITH_CRITERIA_URL = path(COMPANY_SUBSCRIBERS_URL, '/search');

// DENORMALIZED CANDIDATE CURRICULUM VITAE
export const DENORMALIZED_CANDIDATE_CURRICULUM_VITAE_URL = path(API_URL, '/denormalized-candidate-curriculum-vitae');
export const COUNT_DENORMALIZED_CANDIDATE_CURRICULUM_VITAE_WITH_CRITERIA_URL = path(
  DENORMALIZED_CANDIDATE_CURRICULUM_VITAE_URL,
  '/count',
);
export const SEARCH_DENORMALIZED_CANDIDATE_CURRICULUM_VITAE_WITH_CRITERIA_URL = path(
  DENORMALIZED_CANDIDATE_CURRICULUM_VITAE_URL,
  '/search',
);

//EMAIL CONFIG
export const EMAIL_CONFIG_URL = path(API_URL, '/email-configs');
export const SEARCH_EMAIL_CONFIG_WITH_CRITERIA_URL = path(EMAIL_CONFIG_URL, '/search');

//EMAIL TEMPLATE
export const EMAIL_TEMPLATE_URL = path(API_URL, '/email-templates');
export const SEARCH_EMAIL_TEMPLATE_WITH_CRITERIA_URL = path(EMAIL_TEMPLATE_URL, '/search');

//EMAIL TEMPLATE VARIABLE
export const EMAIL_TEMPLATE_VARIABLE_URL = path(API_URL, '/email-templates-variables');
export const SEARCH_EMAIL_TEMPLATE_VARIABLE_WITH_CRITERIA_URL = path(EMAIL_TEMPLATE_VARIABLE_URL, '/search');

//FILTER SEARCH HISTORY
export const FILTER_SEARCH_HISTORY_URL = path(API_URL, '/filter-search-history');
export const SEARCH_ALL_FILTER_SEARCH_HISTORY_URL = path(FILTER_SEARCH_HISTORY_URL, '/all');

// JOB URL
export const JOB_APPLIES_URL = path(API_URL, '/job-applies');
export const ACCEPT_CV_REQUEST_URL = path(JOB_APPLIES_URL, '/accept-cv-request');
export const APPLY_CV_URL = path(JOB_APPLIES_URL, '/apply');
export const COUNT_ALL_STATUS_BY_JOB_URL = path(JOB_APPLIES_URL, '/count');
export const INITIAL_EVALUATION_URL = path(JOB_APPLIES_URL, '/initial-evaluation');
export const MY_JOB_APPLIES_URL = path(JOB_APPLIES_URL, '/my-jobs');
export const SEARCH_JOB_APPLIES_WITH_CRITERIA_URL = path(JOB_APPLIES_URL, '/search');
export const VIEW_CV_REQUEST_URL = path(JOB_APPLIES_URL, '/view-cv-request');

// JOB APPLY PROCESS
export const JOB_APPLY_PROCESS_URL = path(API_URL, '/job-apply-process');
export const CONFIRM_INTERVIEW_ATTENDANCE_URL = path(JOB_APPLY_PROCESS_URL, '/confirm-interview-attendance');
export const SEARCH_JOB_APPLY_PROCESS_WITH_CRITERIA_URL = path(JOB_APPLY_PROCESS_URL, '/search');
export const CONFIRM_INTERVIEWED_URL= path(JOB_APPLY_PROCESS_URL,'/confirm-interviewed')

export const JOB_URL = path(API_URL, '/jobs');
export const CONFIRM_JOB_URL = path(JOB_URL, '/confirm');
export const JOB_PAYMENT_URL = path(JOB_URL, '/payment');
export const PREFERRED_CV_URL = path(JOB_URL, '/preferred');
export const SEARCH_JOB_WITH_CRITERIA_URL = path(JOB_URL, '/search/company');
export const TOTAL_WAITING_JOB_URL = path(JOB_URL, '/total-waiting-confirm');

//PAYMENT
export const PAYMENT_URL = path(API_URL, '/payments');

export const DEPOSIT_PAYMENT_URL = path(PAYMENT_URL, '/deposit');

export const PAYMENT_INFO_URL = path(API_URL, '/payment-info');

// ROLE PERMISSION URL
export const ROLE_PERMISSION_URL = path(API_URL, '/roles');
export const SEARCH_ALL_ROLE_PERMISSION_WITH_TYPE_URL = path(ROLE_PERMISSION_URL, '/all-permission');
export const SEARCH_ROLE_PERMISSION_WITH_CRITERIA_URL = path(ROLE_PERMISSION_URL, '/search');

// SYSTEM CONFIG URL
export const SYSTEM_CONFIG_URL = path(API_URL, '/system-configs');
export const DEVELOPER_FEE_PERCENTAGE_SYSTEM_CONFIG_URL = path(SYSTEM_CONFIG_URL, '/developer-fee-percentage');

// TRANSACTION HISTORY URL
export const TRANSACTION_HISTORY_URL = path(API_URL, '/transaction-historys/company'); // API ĐANG SAI NGỮ PHÁP
export const SEARCH_TRANSACTION_HISTORY_WITH_CRITERIA_URL = path(TRANSACTION_HISTORY_URL, '/search');

// TRANSACTION REQUEST URL
export const TRANSACTION_REQUEST_URL = path(API_URL, '/transaction-requests/company');
export const HANDLE_TRANSACTION_REQUEST_URL = path(TRANSACTION_REQUEST_URL, '/handle');
export const SEARCH_TRANSACTION_REQUEST_WITH_CRITERIA_URL = path(TRANSACTION_REQUEST_URL, '-search');

// USER URL
export const USER_URL = path(API_URL, '/users');
export const USER_COMPANY_PROFILE_URL = path(USER_URL, '/company');
export const USER_FAVOURITE_JOB_URL = path(USER_URL, '/favourite-job');
export const USER_INFOR_URL = path(USER_URL, '/info');
export const USER_CHANGE_PASSWORD_URL = path(USER_URL, '/password');
export const SEARCH_USER_WITH_CRITERIA_URL = path(USER_URL, '/search');

// USER TYPE PERMISSION URL
export const USER_TYPE_PERMISSION_URL = path(API_URL, '/user-type-permission');
export const SEARCH_ALL_USER_TYPE_PERMISSION_WITH_CRITERIA_URL = path(USER_TYPE_PERMISSION_URL, '/all');

//Feedback
export const FEEDBACK_URL = path(API_URL, '/feedback');

// Ver 2 - Transaction history
export const TRANSACTION_HISTORY_URL_V2 = path(
  API_URL_V2,
  '/transaction-histories',
);
